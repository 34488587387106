import React from "react"

import Nav from "./m01_nav"
import Footer from "./m02_footer"
import { Box } from "@sqymagma/elements"
import Helmet from "react-helmet"

const Layout = ({ children, title, desc, keywords, url }) => {
  const ruta = url !== "" && typeof url !== "undefined" ? url : ""
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
        <meta name="description" content={desc} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={"https://livingalternatives.es/" + ruta} />
        <meta property="og:site_name" content="Living Alternatives" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/thesaurus-cms/image/upload/v1590655686/living-alternatives/livingalternatives_pnazba.jpg"
        />
        <meta property="og:url" content={"https://livingalternatives.es/" + ruta} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Nav
        lastmintext="Ahora puedes recibir quincenalmente la mejor propuesta de inversión de local."
        lastminlink="Me interesa"
      />
      <Box zIndex="0" maxWidth="100%">
        {children}

        <Footer />
      </Box>
    </>
  )
}

export default Layout
