import { Box as MagmaBox } from "@sqymagma/elements"
import styled from "styled-components"
import { system } from "styled-system"

const Box = styled(MagmaBox)`
  ${system({
    boxwidth: { property: "width", scale: "boxwidth" },
    np: { property: "padding", scale: "negateSpacing" },
    npt: { property: "paddingTop", scale: "negateSpacing" },
    npr: { property: "paddingRight", scale: "negateSpacing" },
    npb: { property: "paddingBottom", scale: "negateSpacing" },
    npl: { property: "paddingLeft", scale: "negateSpacing" },
    npx: { properties: ["paddingLeft", "paddingRight"], scale: "negateSpacing" },
    npy: { properties: ["paddingTop", "paddingBottom"], scale: "negateSpacing" },
    nm: { property: "margin", scale: "negateSpacing", defaultScale: "negateSpacing" },
    nmt: { property: "marginTop", scale: "negateSpacing" },
    nmr: { property: "marginRight", scale: "negateSpacing" },
    nmb: { property: "marginBottom", scale: "negateSpacing" },
    nml: { property: "marginLeft", scale: "negateSpacing" },
    nmx: { properties: ["marginLeft", "marginRight"], scale: "negateSpacing" },
    nmy: { properties: ["marginTop", "marginBottom"], scale: "negateSpacing" },
    npadding: { property: "padding", scale: "negateSpacing" },
    npaddingTop: { property: "paddingTop", scale: "negateSpacing" },
    npaddingRight: { property: "paddingRight", scale: "negateSpacing" },
    npaddingBottom: { property: "paddingBottom", scale: "negateSpacing" },
    npaddingLeft: { property: "paddingLeft", scale: "negateSpacing" },
    npaddingX: { properties: ["paddingLeft", "paddingRight"], scale: "negateSpacing" },
    npaddingY: { properties: ["paddingTop", "paddingBottom"], scale: "negateSpacing" },
    nmargin: { property: "margin", scale: "negateSpacing", defaultScale: "spacing" },
    nmarginTop: { property: "marginTop", scale: "negateSpacing" },
    nmarginRight: { property: "marginRight", scale: "negateSpacing" },
    nmarginBottom: { property: "marginBottom", scale: "negateSpacing" },
    nmarginLeft: { property: "marginLeft", scale: "negateSpacing" },
    nmarginX: { properties: ["marginLeft", "marginRight"], scale: "negateSpacing" },
    nmarginY: { properties: ["marginTop", "marginBottom"], scale: "negateSpacing" },
  })}
`
export default Box
