import React, { useState } from "react"
import { useLocation } from "@reach/router"

import { motion, AnimatePresence } from "framer-motion"
import { Flex, Box, Text } from "@sqymagma/elements"
import styled from "styled-components"
import Btn from "../components/button"
import BtnNav from "../components/buttonNav"
import Tick from "../components/tick"
import { Link } from "gatsby"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import ContactModal from "./m26_contactModal"
import Linkexternal from "../components/linkexternal"
import links from "../data/links"

//import "./style.css"

const CsselTlftop = styled(Linkexternal)`
  &:hover {
    text-decoration: underline !important;
  }
`

const N = styled(Box)`
  transition: all 0.3s;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0px;
`

const NavContainer = styled(Flex)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  ${p => p.theme.mq.l} {
    width: auto;
    position: static;
    height: auto;
    background-color: transparent;
    display: flex;
  }
`

const CssLink = styled(Link)`
  color: ${p => (p.active ? "red" : "black")};
  &:hover span {
    color: ${p => p.theme.color.brand01} !important;
  }
`

const Contact = ({ setOpen }) => {
  return (
    <>
      <Box display={["flex", "none"]}>
        <Btn size="small" onClick={() => setOpen()} padding="0px 7px" id="TM7-Header-Nav-Contacto">
          <IcnSystem name="icnphone" height="32px" width="20px" fill="text02" padding="7px 0px" />
        </Btn>
      </Box>
      <Flex alignItems="center" display={["none", "flex"]}>
        <Text color="brand01" mx="spc" textStyle="p" alignItems="center" weight="bold">
          <CsselTlftop href="tel:+34915613719" id="TM6-Header-Nav-Llamada" title="telefono">
            915 613 719
          </CsselTlftop>
        </Text>
        <Btn
          size="regular"
          onClick={() => setOpen()}
          width={["136px"]}
          margin={["", "0 12px"]}
          id="TM7-Header-Nav-Contacto"
        >
          Contacto
        </Btn>
      </Flex>
    </>
  )
}

const MobileMenu = ({ setClose, ...props }) => {
  return (
    <Box bg="bg03" color="text01" textStyle="p" textAlign="center" {...props}>
      <AnimatePresence>
        <motion.div
          key="subscribe-modal"
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          <Flex
            bg="bg03"
            alignItems="center"
            width={["auto", "736px", "832px", "1120px", "1312px"]}
            m={["0 24px", "0 auto"]}
            height={["64px", "88px", "88px", "120px", "128px"]}
            justifyContent="space-between"
          >
            <Link to="/">
              <Flex flexDirection="row">
                <IcnSystem name="lsicon" height="32px" fill="brand03" />
                <IcnSystem name="lsname" height="22px" fill="text01" padding="5px 24px 0px 32px" />
              </Flex>
            </Link>
            <Flex alignItems="center">
              <NavContainer
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                bg="bg01"
              >
                <Box display="block">
                  <Text textStyle="h3" color="text01">
                    Menú
                  </Text>
                  <Tick align="center" />
                </Box>
                <Flex as="ul" alignItems="center" flexDirection="column">
                  {links.map((link, idx) => {
                    return (
                      <Box as="li" key={idx} m={["0 0 16px", "0 0 16px", "0 0 16px", "0 8px"]}>
                        <Link to={link.href} id={link.id}>
                          <Text
                            display={["inline", "inline", "inline", "none"]}
                            textStyle="subtitle"
                            color={["brand01", "brand01", "brand01", "text01"]}
                          >
                            {link.label}
                          </Text>
                        </Link>
                      </Box>
                    )
                  })}
                </Flex>
              </NavContainer>
              <Text
                color="brand01"
                mx="spc"
                display={["none", "flex"]}
                textStyle="p"
                alignItems="center"
                weight="bold"
              >
                <CsselTlftop href="tel:+34915613719" id="TM6-Header-Nav-Llamada">
                  915 613 719
                </CsselTlftop>
              </Text>
            </Flex>
          </Flex>
          <BtnNav
            onClick={() => setClose()}
            position="absolute"
            right={["20px", "32px"]}
            top={["12px", "32px"]}
            fill="brand01"
          />
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}

const LinkElement = ({ elemento, link, active }) => {
  return (
    <Box
      as="li"
      key={elemento.id}
      css={active ? "border-bottom: 2px solid #27AE60; line-height:18px;" : null}
      m={["0 0 16px", "0 0 16px", "0 0 16px", "0 8px"]}
      active={active}
    >
      <CssLink to={link.href} active={active} id={link.id}>
        <Text
          display={["none", "none", "none", "inline"]}
          textStyle="p"
          color={["brand01", "brand01", "brand01", "text01"]}
        >
          {link.label}
        </Text>
      </CssLink>
    </Box>
  )
}

const Nav = ({ active, ...props }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const location = useLocation()

  const LinksMenu = links.map((link, elemento) => {
    const active = link.href === location.pathname
    return <LinkElement active={active} link={link} elemento={elemento} />
  })

  return (
    <N
      width="100%"
      bg="bg03"
      color="text01"
      textStyle="p"
      textAlign="center"
      className="nav"
      {...props}
    >
      <Flex
        bg="bg03"
        alignItems="center"
        width={["calc(100% - 32px)", "calc(100% - 80px)", "832px", "1120px", "1488px"]}
        m={["0 12px", "0 auto"]}
        height={["64px", "88px", "88px", "120px", "128px"]}
        justifyContent="space-between"
      >
        <Link to="/">
          <Flex flexDirection="row" alignItems="center">
            <IcnSystem
              name="lsicon"
              height={["22px", "28px", "28px", "33px"]}
              fill="brand03"
              margin={["0px 4px", "-1px 8px 1px 0"]}
            />
            <IcnSystem
              name="lsname"
              height={["17px", "23px", "23px", "27px"]}
              fill="text01"
              margin={["7px 8px 0px 8px", "8px 8px 0px"]}
            />
          </Flex>
        </Link>

        <Flex alignItems="center">
          <Flex as="ul" alignItems="center" flexDirection={["row"]}>
            {LinksMenu}
          </Flex>
          <Contact setOpen={() => setOpenContact(true)} />
          <BtnNav
            display={["block", "block", "block", "none"]}
            position="static"
            margin={["0 0 0 8px"]}
            width={["40px", "64px"]}
            height={["40px", "64px"]}
            open={!openMenu}
            onClick={() => setOpenMenu(true)}
          />
        </Flex>
      </Flex>
      {openContact && (
        <ContactModal
          setClose={() => setOpenContact(false)}
          tagidemailbot="TM76-ContactaNosotros-MóduloEmail-BotonNext"
          tagidnombrebot="TM77-ContactaNosotros-MóduloNombre-BotonNext"
          tagidtelefonobot="TM78-ContactaNosotros-MóduloTelefono-BotonNext"
          tagidcuentanosbot="TM79-ContactaNosotros-ModuloCuentanos-BotonNext"
          tagidllamada="TM80-ContactaNosotros-Llamada-TextoLlamada"
        />
      )}
      {openMenu && <MobileMenu setClose={() => setOpenMenu(false)} />}
    </N>
  )
}

export default Nav
