const links = [
  {
    href: "/invertir-en-locales-comerciales/",
    label: "Invertir en locales",
    id: "TM1-Header-Nav-InvertirLocales",
  },
  {
    href: "/como-lo-hacemos/",
    label: "Cómo lo hacemos",
    id: "TM2-Header-Nav-CambioUso",
  },
  {
    href: "/convertir-local-en-vivienda-madrid-casos-practicos/",
    label: "Casos",
    id: "TM3-Header-Nav-CasosPrácticos",
  },
  {
    href: "/preguntas-frecuentes-como-hacer-un-cambio-de-uso/",
    label: "FAQ",
    id: "TM4-Header-Nav-FAQ",
  },
  {
    href: "/living-alternatives-quienes-somos/",
    label: "Quiénes somos",
    id: "TM5-Header-Nav-QuienesSomos",
  },
]

export default links
