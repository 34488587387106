import React, { useState, useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReactDOM from "react-dom"
import { Flex, Box, Text, Stack } from "@sqymagma/elements"
import styled from "styled-components"
import Tick from "../components/tick"
import BtnNav from "../components/buttonNav"
import Loader from "../components/Loader"
import Btn from "../components/button"
import { IcnSystem } from "../components/IconSystem/IcnSystem"

const StyledBox = styled(Box)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: left;
  border: 1px solid #f0f0f0;
  border-bottom: 3px solid #dadada;
  border-radius: 0 0 4px 4px;
  transition: 0.3s ease-in;
  box-sizing: content-box;
`

const StyledInput = styled(Field)`
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border: none;
  outline: none;
  padding: 0px 20px 15px 20px;
  font-family: ${p => p.theme.fontFamily.secondary};
  background: transparent;
`

const NextBtn = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 50px;
  height: 100%;
  color: ${p => p.theme.color.brand01};
  border: none;
  transition: 0.3s;
  cursor: pointer;
  outline: none;
  font-size: 50px;
  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
  &:hover {
    background: #f0f6f4;
  }
`

const ProgressBar = styled(Box)`
  height: 3px;
  position: absolute;
  bottom: -4px;
  border-radius: 0 0 0 3px;
  z-index: 500;
  right: 0;
  left: 0;
  transition: 0.3s ease-in;
`

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email("Campo email debería ser válido usuario@dominio.com")
    .required("Campo Email es obligatorio"),
  _phone: Yup.number(),
  name: Yup.string()
    .min(3, "Debería tener minimo un caracter")
    .max(50, "No debería tener más que 50 caracteres"),

  message: Yup.string().min(10, "Debería tener mínimo 10 caracteres"),
})

const ContactModal = ({
  setClose,
  tagidemailbot,
  tagidnombrebot,
  tagidtelefonobot,
  tagidcuentanosbot,
  tagidllamada,
}) => {
  const [emailVisible, setEmailVisible] = useState(true)
  const [phoneVisible, setPhoneVisible] = useState(false)
  const [nameVisible, setNameVisible] = useState(false)
  const [messageVisible, setMessageVisible] = useState(false)
  const [loaderVisible, setLoaderVisible] = useState(false)

  const [count, setCount] = useState(1)
  const [bar, setBar] = useState(0)

  const emailInput = useRef()
  const phoneInput = useRef()
  const nameInput = useRef()
  const messageInput = useRef()

  const emailFocus = () => {
    emailInput.current.focus()
  }

  const phoneFocus = () => {
    phoneInput.current.focus()
    setPhoneVisible(true)
    setEmailVisible(false)
    setBar("25%")
    setCount(2)
  }

  const nameFocus = () => {
    nameInput.current.focus()
    setNameVisible(true)
    setPhoneVisible(false)
    setBar("50%")
    setCount(3)
  }

  const messageFocus = () => {
    messageInput.current.focus()
    setMessageVisible(true)
    setNameVisible(false)
    setBar("75%")
    setCount(4)
  }

  const formSubmit = () => {
    setBar("100%")
    setTimeout(() => {
      setLoaderVisible(true)
    }, 500)
  }

  /* Server State Handling */
  const [serverState, setServerState] = useState()
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: "https://formspree.io/mknvdgnr",
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(true, "Thanks!")
      })
      .catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }
  return ReactDOM.createPortal(
    <Flex
      bg="bg01"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex={999999999}
    >
      <BtnNav
        onClick={() => setClose()}
        position="absolute"
        right={["24px", "40px"]}
        top={["24px", "40px"]}
        fill="brand01"
      />
      <AnimatePresence>
        <motion.div
          key="subscribe-modal"
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <Text textStyle="h3" color="text01">
              Contacta con nosotros
            </Text>
            <Tick align="center" />
            <Text
              textStyle="p1"
              color="text03"
              width={["255px", "455px", "530px"]}
              mb={["24px", "64px", "40px", "48px", "64px"]}
            >
              ¿Quieres invertir en proyectos de cambio de uso de locales comerciales a viviendas?
              Déjanos tus datos y nos pondremos en contacto contigo:
            </Text>

            <Formik
              initialValues={{ email: "", _phone: "", name: "", message: "" }}
              onSubmit={handleOnSubmit}
              validationSchema={formSchema}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form id="fs-frm" noValidate>
                  <Box
                    textStyle="h2"
                    position="relative"
                    width={["290px", "600px", "768px"]}
                    height={["90px", "90px", "120px"]}
                  >
                    <Box display={serverState ? "none" : "block"}>
                      <Loader display={loaderVisible ? "flex" : "none"} />
                      <StyledBox
                        bg="bg03"
                        opacity={emailVisible ? "1" : "0"}
                        zIndex={emailVisible ? "500" : "-500"}
                      >
                        <Text
                          as="label"
                          htmlFor="email"
                          caps
                          textStyle="p"
                          color="brand01"
                          p="15px 20px 0 20px"
                        >
                          ¿Cuál es tu email?
                        </Text>
                        <Box fontSize={["20px", "20px", "26px"]} p={["0px 20px 15px 20px"]}>
                          <StyledInput
                            id="email"
                            name="email"
                            type="email"
                            autoFocus
                            innerRef={emailInput}
                            onKeyDown={e => {
                              if (e.which === 9 || e.which === 13) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </Box>

                        <NextBtn
                          as="button"
                          type="button"
                          bg={touched.email && !errors.email ? "#f0f6f4" : null}
                          onClick={touched.email && !errors.email ? phoneFocus : emailFocus}
                          id={tagidemailbot}
                        >
                          <Box display={{ default: "none", s: "block" }}>
                            <IcnSystem name="aright" length={[40, 55]} fill="brand01" />
                          </Box>
                          <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                            display={{ default: "flex", s: "none" }}
                            bg="#f0f6f4"
                          >
                            <Text textStyle="h6" color="brand01">
                              &#10095;
                            </Text>
                          </Flex>
                        </NextBtn>

                        <Flex
                          justifyContent="space-between"
                          position="absolute"
                          bottom="-60px"
                          height="50px"
                          width={["200px", "auto"]}
                        >
                          <Box textAlign="left" ml="spc">
                            <Text color="support01" textStyle="p1">
                              <ErrorMessage name="email" className="errorMsg" component="p" />
                            </Text>
                          </Box>
                        </Flex>
                      </StyledBox>

                      <StyledBox
                        bg="bg03"
                        opacity={phoneVisible ? "1" : "0"}
                        zIndex={phoneVisible ? "500" : "-500"}
                      >
                        <Text
                          as="label"
                          htmlFor="phone"
                          caps
                          textStyle="p"
                          color="brand01"
                          p="15px 20px 0 20px"
                        >
                          ¿Cuál es tu teléfono?
                        </Text>
                        <Box fontSize={["20px", "20px", "26px"]} p={["0px 20px 15px 20px"]}>
                          <StyledInput
                            id="phone"
                            name="_phone"
                            type="number"
                            innerRef={phoneInput}
                            onKeyDown={e => {
                              if (e.which === 9 || e.which === 13) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </Box>

                        <NextBtn
                          as="button"
                          type="button"
                          bg={touched._phone && !errors._phone ? "#f0f6f4" : null}
                          onClick={touched._phone && !errors._phone ? nameFocus : phoneFocus}
                          id={tagidtelefonobot}
                        >
                          <Box display={{ default: "none", s: "block" }}>
                            <IcnSystem name="aright" length={[40, 55]} fill="brand01" />
                          </Box>
                          <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                            display={{ default: "flex", s: "none" }}
                            bg="#f0f6f4"
                          >
                            <Text textStyle="h6" color="brand01">
                              &#10095;
                            </Text>
                          </Flex>
                        </NextBtn>

                        <Flex
                          justifyContent="space-between"
                          position="absolute"
                          bottom="-60px"
                          height="50px"
                          width={["200px", "auto"]}
                        >
                          <Box textAlign="left" ml="spc">
                            <Text color="support01" textStyle="p1">
                              <ErrorMessage name="_phone" className="errorMsg" component="p" />
                            </Text>
                          </Box>
                        </Flex>
                      </StyledBox>

                      <StyledBox
                        bg="bg03"
                        opacity={nameVisible ? "1" : "0"}
                        zIndex={nameVisible ? "500" : "-500"}
                      >
                        <Text
                          as="label"
                          htmlFor="name"
                          caps
                          textStyle="p"
                          color="brand01"
                          p="15px 20px 0 20px"
                        >
                          ¿Cómo te llamas?
                        </Text>
                        <Box fontSize={["20px", "20px", "26px"]} p={["0px 20px 15px 20px"]}>
                          <StyledInput
                            id="name"
                            name="name"
                            type="text"
                            innerRef={nameInput}
                            onKeyDown={e => {
                              if (e.which === 9 || e.which === 13) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </Box>

                        <NextBtn
                          as="button"
                          type="button"
                          bg={touched.name && !errors.name ? "#f0f6f4" : null}
                          onClick={touched.name && !errors.name ? messageFocus : nameFocus}
                          id={tagidnombrebot}
                        >
                          <Box display={{ default: "none", s: "block" }}>
                            <IcnSystem name="aright" length={[40, 55]} fill="brand01" />
                          </Box>
                          <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                            display={{ default: "flex", s: "none" }}
                            bg="#f0f6f4"
                          >
                            <Text textStyle="h6" color="brand01">
                              &#10095;
                            </Text>
                          </Flex>
                        </NextBtn>

                        <Flex
                          justifyContent="space-between"
                          position="absolute"
                          bottom="-60px"
                          height="50px"
                          width={["200px", "auto"]}
                        >
                          <Box textAlign="left" ml="spc">
                            <Text color="support01" textStyle="p1">
                              <ErrorMessage name="name" className="errorMsg" component="p" />
                            </Text>
                          </Box>
                        </Flex>
                      </StyledBox>
                      <StyledBox
                        bg="bg03"
                        opacity={messageVisible ? "1" : "0"}
                        zIndex={messageVisible ? "500" : "-500"}
                      >
                        <Text
                          as="label"
                          htmlFor="message"
                          caps
                          textStyle="p"
                          color="brand01"
                          p="15px 20px 0 20px"
                        >
                          Cuéntanos más
                        </Text>
                        <Box fontSize={["20px", "20px", "26px"]} p={["0px 20px 15px 20px"]}>
                          <StyledInput
                            id="message"
                            name="message"
                            component="textarea"
                            innerRef={messageInput}
                            onKeyDown={e => {
                              if (e.which === 9 || e.which === 13) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </Box>

                        <NextBtn
                          as="button"
                          type="submit"
                          disabled={isSubmitting}
                          bg={touched.message && !errors.message ? "#f0f6f4" : null}
                          onClick={
                            touched.message && !errors.message
                              ? formSubmit
                              : () => messageInput.current.focus()
                          }
                          id={tagidcuentanosbot}
                        >
                          <Box display={{ default: "none", s: "block" }}>
                            <IcnSystem name="aright" length={[40, 55]} fill="brand01" />
                          </Box>
                          <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                            display={{ default: "flex", s: "none" }}
                            bg="#f0f6f4"
                          >
                            <Text textStyle="h6" color="brand01">
                              &#10095;
                            </Text>
                          </Flex>
                        </NextBtn>

                        <Flex
                          justifyContent="space-between"
                          position="absolute"
                          bottom="-60px"
                          height="50px"
                          width={["200px", "auto"]}
                        >
                          <Box textAlign="left" ml="spc">
                            <Text color="support01" textStyle="p1">
                              <ErrorMessage name="message" className="errorMsg" component="p" />
                            </Text>
                          </Box>
                        </Flex>
                      </StyledBox>

                      <ProgressBar width={bar} bg="brand01" />
                      <Box position="absolute" bottom="-30px" right="20px">
                        <Text color="text03" textStyle="p_t">
                          {count}/4
                        </Text>
                      </Box>
                    </Box>

                    <AnimatePresence>
                      {serverState && (
                        <motion.div
                          initial={{ y: 70, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 70, opacity: 0 }}
                          transition={{ delay: 0.7 }}
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <Box
                            width="100%"
                            height="100%"
                            position="relative"
                            mb={["40px", "40px", "56px"]}
                          >
                            <Text
                              display="flex"
                              textStyle="subtitle"
                              border="1px solid #777777"
                              borderRadius="2px"
                              width="100%"
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                              mb="20px"
                            >
                              Gracias, pronto te contactaremos
                            </Text>
                            <Box position="absolute" right="0">
                              <Btn
                                type="reset"
                                size="regular"
                                width={["150px", "150px", "200px"]}
                                height={["40px", "40px", "56px"]}
                                onClick={() => setClose()}
                              >
                                Terminar
                              </Btn>
                            </Box>
                          </Box>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>
                </Form>
              )}
            </Formik>

            <Flex
              opacity={serverState ? "0" : "1"}
              zIndex={serverState ? "-500" : "10"}
              flexDirection={["column", "row"]}
              width={["240px", "370px", "450px"]}
              height="72px"
              bg={["bg01", "bg02"]}
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              my={["70px", "60px"]}
            >
              <Text textStyle="p_t" color="text03" m={["0", "10px", "10px", "15px", "20px"]}>
                Si lo prefieres llámanos
              </Text>
              <Text color="brand01" textStyle="subtitle" m={["0", "10px"]}>
                <a href="tel:915 613 719" id={tagidllamada}>
                  915 613 719
                </a>
              </Text>
            </Flex>
          </Stack>
        </motion.div>
      </AnimatePresence>
    </Flex>,
    document.getElementById("modal")
  )
}

export default ContactModal
