import React, { useState } from "react"
import { Box, Text, Flex } from "@sqymagma/elements"
import Block from "../components/block"
import Btn from "../components/button"
import Linkexternal from "../components/linkexternal"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import { Link } from "gatsby"
import ContactModal from "./m26_contactModal"
import styled from "styled-components"

const CsslaLink = styled(Linkexternal)`
  display: block;
  &:hover {
    text-decoration: underline !important;
  }
`
const CssPoliticaLink = styled(Link)`
  &:hover {
    text-decoration: underline !important;
  }
`

const Footer = () => {
  const [openContact, setOpenContact] = useState(false)
  return (
    <Box bg="brand01" width="100%" textAlign="center" color="text02" mx="0px">
      <Block>
        <Box px={["spc", "auto"]}>
          <Flex pt={["spc3", "spc6"]} pb={["spc4", "spc8"]} flexDirection={["column", "row"]}>
            <Box width="64px" display={["none", "block", "none"]} />
            <Box width={["auto", "176px", "208px", "256px", "304px"]} textAlign="left" mr="spc">
              <Box height={["48px", "48px", "64px"]}>
                <Text textStyle="h4">Living Alternatives</Text>
              </Box>
              <Box pb="spc1">
                <Text textStyle="p">
                  <CsslaLink href="tel:+34915613719" id="TM8-Footer-SecciónContacto-Llamada">
                    +34 915 613 719
                  </CsslaLink>
                  <CsslaLink href="mailto:hola@livingalternatives.es">
                    hola@livingalternatives.es
                  </CsslaLink>
                  <CsslaLink href="https://goo.gl/maps/gDYbBSfz6aN1rXZW7">
                    Calle botánica, 4. 28028 - Madrid
                  </CsslaLink>
                </Text>
              </Box>
              <Box pb="spc" textAlign="left" ml="0px">
                <Btn
                  margin="0px"
                  size="regular"
                  type="secondary"
                  onClick={() => setOpenContact(true)}
                  id="TM9-Footer-SecciónContacto-Contacto"
                >
                  Contacto
                </Btn>
              </Box>
            </Box>
            <Flex
              width={["auto", "32px", "48px", "64px", "80px"]}
              ml={["0", "0", "spc"]}
              mr={["0", "spc"]}
              mb="spc1"
              pt={["none", "spc3", "spc4"]}
              flexDirection={["row", "column"]}
              textAlign="center"
            >
              <Linkexternal
                href="https://www.facebook.com/Living-Alternatives-102818491411375/"
                rel="noopener noreferrer"
                alt="facebook"
                title="facebook"
              >
                <IcnSystem
                  name="fb"
                  height="24px"
                  fill="text02"
                  margin="8px "
                  css={`
                    &:hover {
                      * {
                        opacity: 0.9;
                      }
                    }
                  `}
                />
              </Linkexternal>{" "}
              <Linkexternal
                href="https://twitter.com/Living_altES"
                target="_blank"
                rel="noopener noreferrer"
                alt="twitter"
                title="twitter"
              >
                <IcnSystem
                  name="twitter"
                  height="24px"
                  fill="text02"
                  margin="8px"
                  padding="2px"
                  css={`
                    &:hover {
                      * {
                        opacity: 0.9;
                      }
                    }
                  `}
                />
              </Linkexternal>
              <Linkexternal
                href="https://www.instagram.com/livingalternatives/"
                rel="noopener noreferrer"
                alt="instagram"
                title="instagram"
              >
                <IcnSystem
                  name="instagram"
                  height="24px"
                  fill="text02"
                  margin="8px "
                  css={`
                    &:hover {
                      * {
                        opacity: 0.9;
                      }
                    }
                  `}
                />
              </Linkexternal>{" "}
              <Linkexternal
                href=" https://www.linkedin.com/company/living-alternatives-es/"
                rel="noopener noreferrer"
                alt="linkedin"
                title="linkedin"
              >
                <IcnSystem
                  name="linkedin"
                  height="24px"
                  fill="text02"
                  margin="8px"
                  padding="2px"
                  css={`
                    &:hover {
                      * {
                        opacity: 0.9;
                      }
                    }
                  `}
                />
              </Linkexternal>
            </Flex>
            <Box
              width={["auto", "352px", "528px", "544px", "640px"]}
              textAlign="left"
              pt={["none", "spc3", "spc4"]}
            >
              <Box mb="spc">
                <Text textStyle="p" pb="32px">
                  Somos un estudio especializado en el cambio de uso de locales a viviendas en
                  Madrid, para convertirlos posteriormente en viviendas reformadas y decoradas con
                  altas rentabilidades en el entorno del 10% vía alquiler de las mismas.
                </Text>
              </Box>

              <Box>
                <Text textStyle="p1">
                  <CssPoliticaLink to="/privacy">
                    <b>Política de Privacidad de acuerdo con GDPR</b>
                  </CssPoliticaLink>
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Block>
      {openContact && <ContactModal setClose={() => setOpenContact(false)} />}
    </Box>
  )
}

export default Footer
