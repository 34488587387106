import React from "react"
import Box from "./Box"

const Block = ({ children, ...props }) => (
  <Box
    mx={["auto"]}
    width={["calc(100% - 32px)", "calc(100% - 80px)", "832px", "1120px", "1488px"]}
    minWidth={[["272px", "736px", "832px", "1120px", "1488px"]]}
    {...props}
  >
    {children}
  </Box>
)

export default Block
