import React from "react"
import styled from "styled-components"
import { Box } from "@sqymagma/elements"
import { IcnSystem } from "../components/IconSystem/IcnSystem"

const ButtonNav = styled(Box)`
  width: ${p => (p.width ? p.width : "40px")};
  height: ${p => (p.height ? p.height : "40px")};
  margin: ${p => (p.margin ? p.margin : "0")};
  position: ${p => (p.position ? p.position : "static")};
  top: ${p => (p.top ? p.top : "0")};
  transform: ${p => (p.translate ? p.translate : "0")};
  right: ${p => (p.right ? p.right : "0")};
  display: ${p => (p.display ? p.display : "block")};
  cursor: pointer;
  transition: 0.3s;
  z-index: 10;
`

const BtnNav = ({
  onClick,
  open,
  position,
  translate,
  margin,
  right,
  top,
  display,
  width,
  height,
  fill,
  ...props
}) => {
  return (
    <ButtonNav
      onClick={onClick}
      open={open}
      position={position}
      translate={translate}
      margin={margin}
      right={right}
      top={top}
      display={display}
      width={width}
      height={height}
      {...props}
    >
      {open ? (
        <IcnSystem name="menu" height={["40px", "64px"]} m="0px" fill={fill} />
      ) : (
        <IcnSystem name="close" height="32px" m="4px" fill={fill} />
      )}
    </ButtonNav>
  )
}

export default BtnNav
